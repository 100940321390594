<div
  mat-dialog-content
  class="d-flex flex-column h-100 container p-3 text-center"
  *transloco="let t"
>
  <mat-icon color="warn" class="warning-icon" *ngIf="showWarningIcon"
    >warning</mat-icon
  >
  <h5>{{ t(dialogData.title || '') || t('areYouSureKey') }}</h5>
  <p
    class="text-muted p-3"
    style="flex: 1; margin-bottom: 1rem; white-space: pre-wrap"
  >
    {{ labelToDisplay }}
  </p>

  <div mat-dialog-actions class="d-flex gap-3 pl-3 pr-3 text-center">
    <button mat-stroked-button class="w-100" (click)="closeDialog(false)">
      {{ t('noKey') }}
    </button>
    <button
      mat-raised-button
      class="response-button__yes w-100"
      (click)="closeDialog(true)"
      data-cy="cyYes"
    >
      {{ t('yesKey') }}
    </button>
  </div>
</div>
